import pkceChallenge from 'pkce-challenge';
import { PKCEChallenge } from './interfaces';
import { OAUTH2_PKCE_CHALLENGE_LENGTH } from './oauth2.constants';
import { getStrategySessionKey } from './util';

export class OAuth2PKCEService {
  static createPKCEChallenge(length?: number): PKCEChallenge {
    return pkceChallenge(length || OAUTH2_PKCE_CHALLENGE_LENGTH);
  }

  static savePKCEChallengeForClientID(
    client_id: string,
    strategy: string,
    pkce: PKCEChallenge,
  ): void {
    window.sessionStorage.setItem(
      this.getPKCESessionKey(client_id, strategy),
      JSON.stringify(pkce),
    );
  }

  static getPKCEChallengeByClientID(
    client_id: string,
    strategy: string,
  ): PKCEChallenge {
    const pkce = window.sessionStorage.getItem(
      this.getPKCESessionKey(client_id, strategy),
    );

    return (pkce && JSON.parse(pkce)) || undefined;
  }

  private static getPKCESessionKey(
    client_id: string,
    strategy: string,
  ): string {
    return `${getStrategySessionKey(client_id, strategy)}:pkce`;
  }
}
