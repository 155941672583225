import { HTTPError } from '@/libs/http';
import { OAuth2ErrorResponse } from '../interfaces';

export function httpErrorToOAuth2Error(
  error: HTTPError,
  state?: string,
): OAuth2ErrorResponse {
  if (!error.response) {
    return {
      error: 'temporarily_unavailable',
      error_description: 'Service unavailable',
      state,
    };
  }

  return {
    error: error.response.data.error || 'server_error',
    error_description:
      error.response?.data.error_description || 'Internal server error',
  };
}
