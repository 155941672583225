import { OAuth2AuthorizationRequest } from '../interfaces';

export function isResponseTypeValid(response_type: string): boolean {
  const responseTypes: Array<OAuth2AuthorizationRequest['response_type']> = [
    'code',
  ];

  return responseTypes.includes(
    response_type as OAuth2AuthorizationRequest['response_type'],
  );
}
