export type OAuth2Error =
  | 'invalid_request'
  | 'invalid_grant'
  | 'unauthorized_client'
  | 'unsupported_grant_type'
  | 'access_denied'
  | 'unsupported_response_type'
  | 'invalid_scope'
  | 'server_error'
  | 'temporarily_unavailable';

export interface OAuth2ErrorResponse {
  error: OAuth2Error;
  error_description?: string;
  state?: string;
}

export interface OAuth2LogoutRequest {
  returnTo?: string;

  client_id?: string;
}

export class OAuth2Exception extends Error {
  oauth2: OAuth2ErrorResponse;

  constructor(error: OAuth2Error, error_description?: string, state?: string) {
    super(error_description);

    this.oauth2 = {
      error,
      error_description,
      state,
    };
  }
}

export function isOAuth2Exception(error: unknown): error is OAuth2Exception {
  return (
    error instanceof OAuth2Exception ||
    (error as OAuth2Exception)?.oauth2 !== undefined
  );
}
