import { Route } from 'vue-router';
import { HttpService } from '../http';
import {
  OAuth2AuthorizationRedirect,
  OAuth2AuthorizationRequest,
  OAuth2AuthorizationResponse,
  OAuth2LogoutRequest,
  OAuth2StrategyState,
} from './interfaces';
import { getStrategySessionKey } from './util';

export class OAuth2Service {
  static getAuthorizationRequestFromRoute(
    route: Route,
  ): OAuth2AuthorizationRequest {
    return {
      response_type: route.query
        .response_type as OAuth2AuthorizationRequest['response_type'],

      client_id: route.query.client_id as string,
      redirect_uri: (route.query.redirect_uri as string) || undefined,
      audience: (route.query.audience as string) || undefined,
      scope: (route.query.scope as string) || undefined,
      state: (route.query.state as string) || undefined,

      code_challenge: (route.query.code_challenge as string) || undefined,
      code_challenge_method:
        (route.query
          .code_challenge_method as OAuth2AuthorizationRequest['code_challenge_method']) ||
        undefined,
    };
  }

  static getAuthorizationResponseFromRoute(
    route: Route,
  ): OAuth2AuthorizationResponse {
    return {
      code: route.query.code as string,
      scope: (route.query.scope as string) || undefined,
      state: (route.query.state as string) || undefined,
    };
  }

  static async authorize(
    request: OAuth2AuthorizationRequest,
  ): Promise<OAuth2AuthorizationResponse> {
    const { data } = await HttpService.get('/oauth2/authorize', {
      params: request,
    });
    return data;
  }

  static async logout(request: OAuth2LogoutRequest): Promise<void> {
    await HttpService.get('/oauth2/logout', {
      params: request,
    });
  }

  static redirectToURI(
    redirect: OAuth2AuthorizationRedirect,
    redirect_uri?: string,
  ): void {
    const uri = new URL(redirect_uri || `${window.location.origin}/callback`);

    // Add redirect keys to uri
    Object.entries(redirect)
      .filter(([, value]) => !!value, []) // Filter out undefined
      .forEach(([key, value]) => uri.searchParams.append(key, value));

    window.location.href = uri.href;
  }

  static setStrategySession(
    client_id: string,
    strategy: string,
    request: OAuth2AuthorizationRequest,
  ): void {
    window.sessionStorage.setItem(
      getStrategySessionKey(client_id, strategy),
      JSON.stringify(request),
    );
  }

  static getStrategySession(
    client_id: string,
    strategy: string,
  ): OAuth2AuthorizationRequest | undefined {
    const session = window.sessionStorage.getItem(
      getStrategySessionKey(client_id, strategy),
    );

    return (session && JSON.parse(session)) || undefined;
  }

  static createStrategyState(state: OAuth2StrategyState): string {
    return window.btoa(JSON.stringify(state));
  }

  static getStrategyState(state: string): OAuth2StrategyState {
    return JSON.parse(window.atob(state));
  }
}
